import {
  FedopsInteractions,
  FORMS_CHECKOUT_DEXT_FQDN,
  FORMS_CHECKOUT_NAMESPACE,
  FORMS_TEMPLATE_IDS,
  FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER,
  SPECS,
} from '../../components/Checkout/constants';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {FormsStoreProps} from '../../types/app.types';
import {FormKind, initFormController} from '@wix/form-viewer/controller';
import {NavigationService} from '../services/NavigationService';
import {CheckoutService} from '../services/CheckoutService';
import {BIService} from '../services/BIService';
import {CheckoutSettingsService} from '../services/CheckoutSettingsService';
import {PaymentService} from '../services/PaymentService';
import {WithResultObserver} from '@wix/function-result-observation/dist/types/worker/withResultObserver.worker';
import {AddressWithContactModel} from '../models/AddressWithContact.model';
import {FormsService} from '../services/FormsService';
import {isShippingFlow} from '../utils/checkoutFlow.utils';
import {toMonitorError} from '../utils/errorMonitor.utils';
import {FormControllerAPI} from '@wix/form-viewer';

export type FormsStoreConfig = {
  flowAPI: ControllerFlowAPI;
  navigationService: NavigationService;
  checkoutService: CheckoutService;
  checkoutSettingsService: CheckoutSettingsService;
  paymentService: PaymentService;
  formsService: FormsService;
  biService: BIService;
  updateFormsLoaded: () => void;
  updateComponent: () => void;
  navigateOnFailure: () => Promise<void>;
  observe: WithResultObserver;
};

export class FormsStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly checkoutService: CheckoutService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly paymentService: PaymentService;
  private readonly navigationService: NavigationService;
  private readonly formsService: FormsService;
  private readonly biService: BIService;
  private areFormsLoaded!: boolean;
  private readonly updateFormsLoaded: () => void;
  private readonly navigateOnFailure: () => Promise<void>;
  private readonly observe: WithResultObserver;
  private readonly updateComponent: () => void;
  private getFormFieldPropertiesByTarget?: FormControllerAPI['getFieldPropertiesByTarget'];

  constructor({
    flowAPI,
    navigationService,
    checkoutService,
    checkoutSettingsService,
    paymentService,
    observe,
    biService,
    updateFormsLoaded,
    updateComponent,
    navigateOnFailure,
    formsService,
  }: FormsStoreConfig) {
    this.flowAPI = flowAPI;
    this.checkoutService = checkoutService;
    this.biService = biService;
    this.navigationService = navigationService;
    this.navigateOnFailure = navigateOnFailure;
    this.updateFormsLoaded = updateFormsLoaded;
    this.updateComponent = updateComponent;
    this.areFormsLoaded = false;
    this.checkoutSettingsService = checkoutSettingsService;
    this.paymentService = paymentService;
    this.observe = observe;
    this.formsService = formsService;

    if (!this.navigationService.isFastFlow) {
      void this.initForms();
    }
  }

  private async initForms(fastFlow?: boolean) {
    await this.checkoutSettingsService.fetchIsCheckoutComposerEnabled();
    const useSingleBillingForm = this.flowAPI.experiments.enabled(SPECS.UseSingleBillingForm);

    const loadSingleCustomerDetailsForms = this.checkoutSettingsService.checkoutComposerEnabled
      ? this.flowAPI.experiments.enabled(SPECS.UseSingleCustomerDetailsFormWithComposer)
      : this.flowAPI.experiments.enabled(SPECS.UseSingleCustomerDetailsForm);
    const shouldLoadOldExtendedFieldsForm =
      fastFlow && !this.flowAPI.experiments.enabled(SPECS.UseSingleCustomerDetailsFormWithComposer);

    const requestedTemplates = [
      ...(loadSingleCustomerDetailsForms
        ? [
            FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO,
            FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_W_ADDITIONAL_INFO,
          ]
        : [
            FORMS_TEMPLATE_IDS.EMAIL,
            FORMS_TEMPLATE_IDS.CONTACT,
            FORMS_TEMPLATE_IDS.ADDITIONAL_INFO,
            FORMS_TEMPLATE_IDS.ADDRESS,
            FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EMAIL,
            FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.CONTACT,
            FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EXTENDED_FIELDS_FORM_ID,
            FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.ADDRESS,
          ]),
      ...(useSingleBillingForm ? [FORMS_TEMPLATE_IDS.BILLING] : [FORMS_TEMPLATE_IDS.VAT_ID]),
    ];

    try {
      this.flowAPI.fedops.interactionStarted(FedopsInteractions.InitFormsInteraction);
      if (this.flowAPI.experiments.enabled(SPECS.ShouldUsePanorama)) {
        this.flowAPI.panoramaClient?.transaction(FedopsInteractions.InitFormsInteraction).start();
      }
      const {validateForm, getFieldPropertiesByTarget} = await initFormController(this.flowAPI, {
        formId: shouldLoadOldExtendedFieldsForm
          ? [FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.EXTENDED_FIELDS_FORM_ID]
          : requestedTemplates,
        formKind: FormKind.EXTENSION,
        namespace: FORMS_CHECKOUT_NAMESPACE,
        enableMultilineAddress: true,
      });
      this.getFormFieldPropertiesByTarget = getFieldPropertiesByTarget;
      this.formsService.setFormValidator(validateForm);
      useSingleBillingForm &&
        this.formsService.setShippingDetailsFieldsProps(
          loadSingleCustomerDetailsForms
            ? {...getFieldPropertiesByTarget(FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO)}
            : {
                ...getFieldPropertiesByTarget(FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.ADDRESS),
                ...getFieldPropertiesByTarget(FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER.CONTACT),
              }
        );
      this.flowAPI.fedops.interactionEnded(FedopsInteractions.InitFormsInteraction);
      if (this.flowAPI.experiments.enabled(SPECS.ShouldUsePanorama)) {
        this.flowAPI.panoramaClient?.transaction(FedopsInteractions.InitFormsInteraction).finish();
      }
    } catch (error) {
      this.flowAPI.errorMonitor.captureException(...toMonitorError(error, 'FormStore initFormController'));
      this.biService.checkoutIntegratingFormsFailure(requestedTemplates, error, this.checkoutService.checkout);
      console.error('initFormController failed', error);
      await this.navigateOnFailure();
      return;
    }
    this.areFormsLoaded = true;
    this.updateFormsLoaded();
  }

  public async init() {
    if (!this.navigationService.isFastFlow) {
      const shippingAddress = this.checkoutService.checkout.shippingDestination;
      const isPickupButShippingFlow = shippingAddress
        ? await this.validateShippingAddress(shippingAddress, this.checkoutService.checkout.hasShippableItems)
        : false;
      this.checkoutService.setIsPickupButShippingFlow(isPickupButShippingFlow);
      this.setExtendedFieldTargetsIfConsolidatedCustomerForm();
    } else if (this.checkoutSettingsService.checkoutComposerEnabled) {
      await this.initForms(true);
      await this.formsService.setExtendedFieldsFormValid(this.checkoutService.checkout.extendedFields);
      this.updateComponent();
    }
  }

  private readonly setExtendedFieldTargetsIfConsolidatedCustomerForm = () => {
    const useSingleCustomerDetailsForm = this.checkoutSettingsService.checkoutComposerEnabled
      ? this.flowAPI.experiments.enabled(SPECS.UseSingleCustomerDetailsFormWithComposer)
      : this.flowAPI.experiments.enabled(SPECS.UseSingleCustomerDetailsForm);

    if (useSingleCustomerDetailsForm && this.getFormFieldPropertiesByTarget) {
      this.formsService.setExtendedFieldsTargets({
        ...this.getFormFieldPropertiesByTarget(FORMS_TEMPLATE_IDS.CUSTOMER_DETAILS_WO_ADDITIONAL_INFO, {
          fqdn: FORMS_CHECKOUT_DEXT_FQDN,
        }),
      });
    }
  };

  private readonly validateBillingAddress = (addressWithContact: AddressWithContactModel): Promise<boolean> => {
    return this.formsService.isAddressValidForBilling(addressWithContact, {
      checkoutSettings: this.checkoutSettingsService.checkoutSettings,
      cashierMandatoryFields: this.paymentService.cashierMandatoryFields,
      checkoutComposerEnabled: this.checkoutSettingsService.checkoutComposerEnabled,
    });
  };

  private readonly validateShippingAddress = (
    addressWithContact: AddressWithContactModel,
    forceShipping: boolean = false
  ): Promise<boolean> => {
    return this.formsService.isAddressValidForShipping(addressWithContact, {
      checkoutSettings: this.checkoutSettingsService.checkoutSettings,
      customField: this.checkoutService.checkout?.customField,
      isShippingFlow:
        forceShipping ||
        isShippingFlow({
          navigationService: this.navigationService,
          checkoutService: this.checkoutService,
        }),
      checkoutComposerEnabled: this.checkoutSettingsService.checkoutComposerEnabled,
    });
  };

  public toProps(): FormsStoreProps {
    return {
      areFormsLoaded: this.areFormsLoaded,
      shippingDetailsFieldsProps: this.formsService.shippingDetailsFieldsProps,
      dataExtendedFieldsTargets: this.formsService.extendedFieldsTargets,
      ...this.observe({
        validateBillingAddress: this.validateBillingAddress,
        validateShippingAddress: this.validateShippingAddress,
      }),
    };
  }
}
